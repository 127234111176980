/* src/components/Card.css */
.card {
    background: white;
    border-radius: 10px;
    box-shadow: 0 15px 30px -15px rgba(0, 0, 0, .45);
    overflow: hidden;
    transition: transform 0.3s ease, box-shadow 0.3s ease;
    margin: 1em;
    flex: 1 1 calc(33.333% - 2em);
    box-sizing: border-box;
    max-width: 300px;
    height: 380px;
  }
  
  .card:hover {
    transform: translateY(-10px);
    box-shadow: 0 8px 16px rgba(0, 0, 0, 0.2);
  }
  
  .card-image {
    width: 100%;
    height: 200px;
    object-fit: cover;
    max-width: fit-content;
  }
  
  .card-content {
    padding: 1em;
    text-align: center;
    width: fit-content;
    height: fit-content;
  }
  
  .card-title {
    font-size: 1.5em;
    margin: 0.5em 0;
    color: #4A90E2;
  }
  
  .card-description {
    font-size: 1em;
    max-width: fit-content;
    color: #003366;
  }
  