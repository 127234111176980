/* src/components/StatsCard.css */

.stats-card {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    padding: 1.5em;
    border-radius: 8px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    background-color: #FFFFFF;
    margin-right: 1em;
    transition: transform 0.3s ease-in-out;
}

.stats-card:hover {
    transform: translateY(-5px);
}

.stats-icon {
    background-color: #4A90E2;
    color: #FFFFFF;
    border-radius: 50%;
    padding: 0.5em;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-right: 1em;
}

.stats-icon svg {
    font-size: 1.5rem;
}

.stats-info {
    flex: 1;
}

.stats-number {
    font-size: 2.5rem;
    font-weight: bold;
    display: block;
    margin-bottom: 0.5em;
}

.stats-label {
    color: #666666;
    font-size: 1rem;
}
