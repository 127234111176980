/* src/components/Home.css */

section#home {
  position: relative;
  height: 100vh;
  overflow: hidden;
  padding-top: 0em;
  margin-top: 0em;
}

.home-content {
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  z-index: 2;
  color: white;
}

.home-text-container {
  margin-top: 5em;
  text-align: left;
  z-index: 3;
  padding: 2em;
  max-width: 50%;
  margin-bottom: 2em;
}

.home-logo {
  max-width: 100%;
  margin-bottom: 1em;
}

.home-text h1, .home-text p {
  margin-bottom: 1em;
  color: #ffffff;
  margin-bottom: 2em;
}

.home-video {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 1;
  overflow: hidden;
  clip-path: polygon(50% 100%, 100% 0, 0 0, 0 100%);
  filter: brightness(50%);
}

.video-background {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  object-fit: cover;
  pointer-events: none;
}

.contact-form {
  position: absolute;
  top: 25%;
  right: 5%;
  transform: translateY(-10%);
  width: 350px;
  height: 400px;
  background-color: rgba(255, 255, 255);
  padding: 1.5em;
  border-radius: 10px;
  box-shadow: 5px 4px 10px rgba(0, 0, 0, 0.2);
  z-index: 3;
}

.contact-form h2 {
  margin-bottom: 1em;
  font-size: 1.5em;
  color: #333;
}

.contact-form p {
  font-size: 1em;
}

.contact-form label {
  color: #333;
}

.contact-form form {
  display: flex;
  flex-direction: column;
}

.contact-form input,
.contact-form textarea {
  margin-bottom: 1em;
  padding: 0.75em;
  border: 1px solid #ccc;
  border-radius: 4px;
  width: 100%;
  box-sizing: border-box;
  max-height: 50px;
}

.contact-form button {
  margin-top: 2em;
  padding: 0.75em 1.5em;
  background-color: #4A90E2;
  color: white;
  border: none;
  border-radius: 20px;
  font-size: 1rem;
  cursor: pointer;
  transition: background-color 0.3s ease, transform 0.2s ease, box-shadow 0.3s ease;
  box-shadow: 5px 4px 10px rgba(0, 0, 0, 0.2);
  position: relative;
  overflow: hidden;
}

.contact-form button:hover {
  background-color: #003366;
}

@media (max-width: 768px) {
  section#home {
    height: auto;
  }

  .home-text-container {
    padding: 1em;
    max-width: 90%;
    margin-top: 1em;
  }

  .home-content {
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  .home-logo {
    width: auto;
    height: auto;
  }

  .home-text h1 {
    font-size: 2em;
  }

  .home-text p {
    font-size: 1em;
  }

  .contact-form button {
    margin-top: 1em;
  }
  .contact-form {
    position: static;
    width: 90%;
    margin-top: 1em;
    transform: translateY(0);
    height: auto;
    background-color: rgba(255, 255, 255);
    padding: 1em;
    box-shadow: 3px 3px 6px rgba(0, 0, 0, 0.1);
  }
}
