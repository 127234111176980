#terms-and-conditions {
    padding: 40px;
}

#terms-and-conditions h1,
#terms-and-conditions h2,
#terms-and-conditions h3 {
    margin-bottom: 20px;
    font-weight: 600;
}

#terms-and-conditions h1 {
    font-size: 2.5em;
    margin-bottom: 30px;
}

#terms-and-conditions h2 {
    font-size: 2em;
    margin-top: 40px;
    margin-bottom: 15px;
}

#terms-and-conditions h3 {
    font-size: 1.5em;
    margin-bottom: 10px;
}

#terms-and-conditions p,
#terms-and-conditions ul {
    font-size: 1rem;
    line-height: 1.75;
    margin-bottom: 15px;
}

#terms-and-conditions ul {
    list-style: disc;
    padding-left: 20px;
}

#terms-and-conditions ul li {
    margin-bottom: 10px;
}

@media (max-width: 768px) {
    #terms-and-conditions {
        padding: 20px;
    }

    #terms-and-conditions h1 {
        font-size: 2em;
    }

    #terms-and-conditions h2 {
        font-size: 1.75em;
    }

    #terms-and-conditions h3 {
        font-size: 1.25em;
    }
}