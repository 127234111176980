/* src/components/Equipment.css */

section#equipment {
  display: flex;
}

.equipment-content {
  display: flex;
  flex-direction: row-reverse;
  justify-content: space-between;
  align-items: stretch;
  flex: 1;
}

.equipment-text-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  flex: 1;
  box-shadow: 0 15px 30px -15px rgba(0, 0, 0, .45);
  padding: 2em;
  border-radius: 8px;
}

.equipment-text {
  margin-right: 2em;
}

.equipment-image {
  flex: 1;
  text-align: center;
}

.equipment-image img {
  max-width: 100%;
  height: 100%;
  border-radius: 8px;
}

h2 {
  color: white;
}

p {
  color: white;
}

.equipment-cards-container {
  display: flex;
  flex-wrap: wrap;
  gap: 1em;
  margin-top: 1em;
}

.equipment-cards-container .stats-card {
  flex: 1 1 25%;
  max-width: 100%;
  min-width: 200px;
}

@media (max-width: 768px) {
  section#equipment {
    flex-direction: column;
    padding: 2em;
  }

  .equipment-content {
    flex-direction: column;
    justify-content: center;
  }

  .equipment-text-container {
    padding: 1em;
    max-width: 100%;
    margin-bottom: 1em;
  }

  .equipment-image img {
    height: auto;
  }

  .equipment-text {
    margin-left: 0;
    text-align: center;
    margin-right: 0;
  }

  .stats-cards-container .stats-card {
    flex: 1 1 100%;
  }
}