/* src/components/TeamCard.css */

.team-card {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
    padding: 1em;
    border-radius: 8px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    background-color: #ffffff;
    transition: transform 0.3s ease-in-out;
    max-height: 350px;
}

.team-card:hover {
    transform: translateY(-5px);
}

.team-card-image {
    width: 100%;
    height: 200px;
    overflow: hidden;
    border-top-left-radius: 8px;
    border-top-right-radius: 8px;
}

.team-card-image img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    transition: transform 0.3s ease-in-out;
}

.team-card:hover .team-card-image img {
    transform: scale(1.1);
}

.team-card-info {
    padding: 1em;
    text-align: center;
    width: 100%;
}

.team-card-info a {
    text-decoration: none;
}

.team-card-info h3 {
    margin-bottom: 0.5em;
    font-size: 1.5em;
}

.team-card-info p {
    margin: 0.5em 0;
    font-size: 1em;
    color: #555555;
    word-wrap: break-word;
}
