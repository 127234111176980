/* src/components/Navbar.css */

nav {
  background-color: #4A90E2;
  color: white;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-bottom: 1em;
  padding-top: 1em;
  position: sticky;
  top: 0;
  width: 100%;
  z-index: 1000;
  overflow: hidden;
  background: linear-gradient(-90deg, #007bff, #0056b3);
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  transition: top 0.3s ease-in-out;
}

.logo img {
  height: 50px;
  margin: 0 1em;
}

.menu-icon {
  display: none;
  font-size: 2rem;
  cursor: pointer;
  margin: 0 1em;
}

.hidden {
  top: -100px;
}

.visible {
  top: 0;
}

nav ul {
  list-style-type: none;
  margin: 0;
  padding: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-grow: 1;
  transition: max-height 0.3s ease-in-out;
}

nav ul li {
  margin: 0 1em;
  position: relative;
}

nav ul li a {
  color: white;
  text-decoration: none;
  transition: color 0.3s;
  font-weight: 500;
}

nav ul li a:hover {
  color: #003366;
}

nav ul li a::after {
  content: '';
  display: block;
  width: 100%; 
  height: 2px; 
  background: #003366; 
  position: absolute; 
  left: 0; 
  bottom: -5px; 
  transform: scaleX(0);
  transition: transform 0.3s ease;
}

nav ul li a:hover::after {
  transform: scaleX(1);
}

nav ul li button {
  background: none;
  border: none;
  cursor: pointer;
  padding: 0.5em;
  margin-left: 0.5em;
  color: white;
  transition: color 0.3s, transform 0.3s;
  font-weight: 500;
}

nav ul li button:hover {
  color: #FFFFFF;
  transform: scale(1.2);
}

nav ul li button:focus {
  outline: none;
}

nav ul li.language-selector {
  display: flex;
  align-items: center;
}

nav ul li.facebook-icon a {
  color: white;
  font-size: 1.5em;
  transition: color 0.3s, transform 0.3s;
}

nav ul li.facebook-icon a:hover {
  color: #003366;
  transform: scale(1.2);
}

@media (max-width: 768px) {
  nav {
    overflow: visible;
    flex-direction: column;
    padding-bottom: 0em;
  }

  .menu-icon {
    display: block;
    font-size: 2.5rem;
  }

  nav ul {
    position: absolute;
    top: 100%;
    left: 0;
    width: 100%;
    background-color: #4A90E2;
    flex-direction: column;
    align-items: flex-start;
    max-height: 0;
    overflow: hidden;
  }

  nav ul.nav-menu.active {
    max-height: 400px;
    padding-top: 1.5em;
  }

  nav ul li {
    text-align: center;
    width: 100%;
    margin: 0em;
    margin-top: 1em;
  }

  nav ul li a {
    width: 100%;
    font-size: 1.2rem;
    padding: 1em;
  }

  nav ul li button {
    width: 100%;
    font-size: 1.2rem;
    padding: 1em;
  }

  .hidden {
    top: 0px;
  }
}