/* src/components/ContactUs.css */

footer {
  margin: 0;
  padding: 0;
}

section#contact-us {
  padding-top: 2em;
  margin-top: 0em;
  background-color: #F5F5F5;
  padding-bottom: 1em;
}

section#contact-us h2 {
  margin: 0em;
  text-align: center;
  padding-top: 0.25em;
}

.contact-columns {
  display: flex;
  justify-content: space-between;
}

.contact-column {
  flex: 1;
  margin: 0 1em;
}

.contact-column h3 {
  color: #4A90E2;
}

.contact-column p,
.contact-column ul {
  color: #003366;
}

.contact-column a {
  text-decoration: none;
  color: #4A90E2;
  transition: color 0.3s;
}

.contact-column a:hover {
  color: #003366;
}

.contact-column ul {
  list-style-type: none;
  padding: 0;
}

.contact-column ul li {
  margin: 0.5em 0;
}

.contact-column ul li a {
  color: #003366;
  text-decoration: none;
  transition: color 0.3s;
}

.contact-column ul li a:hover {
  color: #4A90E2;
}

.mini-footer {
  text-align: center;
  font-size: 0.75em;
}

.mini-footer p {
  padding: 0;
  margin: 0;
}

.mini-footer a {
  text-decoration: none;
  margin-left: 5px;
}

.mini-footer a:hover {
  text-decoration: underline;
}

@media (max-width: 767px) {
  .contact-columns {
    flex-direction: column;
    padding-top: 1em;
    padding-bottom: 1em;
  }

  section#contact-us h2 {
    font-size: 2em;
  }

  .contact-column {
    width: 100%;
    max-width: 100%;
    text-align: center;
    margin: 0.5em 0;
  }

  .contact-column h1 {
    font-size: 3em;
  }

  .contact-column h3 {
    font-size: 1.5em;
  }

  .contact-column p,
  .contact-column ul {
    font-size: 0.9em;
  }

  .contact-column ul li {
    font-size: 0.9em;
  }
}