/* src/components/CustomButton.css */

.custom-button {
  padding: 0.75em 1.5em;
  background-color: #4A90E2;
  color: white;
  border: none;
  border-radius: 20px;
  font-size: 1rem;
  cursor: pointer;
  transition: background-color 0.3s ease, transform 0.2s ease, box-shadow 0.3s ease;
  box-shadow: 0 4px 15px rgba(0, 0, 0, 0.1);
  position: relative;
  overflow: hidden;
}

.custom-button::before {
  content: "";
  position: absolute;
  top: 50%;
  left: 50%;
  width: 300%;
  height: 300%;
  background-color: rgba(255, 255, 255, 0.15);
  transition: all 0.5s;
  border-radius: 50%;
  transform: translate(-50%, -50%) scale(0);
}

.custom-button:hover::before {
  transform: translate(-50%, -50%) scale(1);
}

.custom-button:hover {
  background-color: #003366;
  transform: scale(1.05);
  box-shadow: 0 6px 20px rgba(0, 0, 0, 0.15);
}

.custom-button:focus {
  outline: none;
}

.custom-button:active {
  transform: scale(1);
  box-shadow: 0 4px 15px rgba(0, 0, 0, 0.1);
}
