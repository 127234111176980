section#gallery {
    padding-top: 1em;
    margin-top: 0em;
}

.contact-form-wrapper {
    display: flex;
    justify-content: space-between;
    gap: 2em;
    margin: 2em;
    position: relative;
    height: 100%;
    box-shadow: 0 15px 10px rgba(0, 0, 0, 0.05);
}

.contact-info-section {
    flex: 0 0 100px;
    max-width: 280px;
    padding: 2em;
    background-color: #f1f1f1;
    border-right: 1px solid #ccc;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    align-items: flex-start;
    border-radius: 8px;
    background-image: linear-gradient(90deg, #fafafa 50%, #ffffff 100%);
}

.contact-info-section p {
    display: flex;
    align-items: center;
    margin-bottom: 1.2em;
    font-size: 1.1em;
    word-wrap: break-word;
}

.contact-info-section p svg {
    margin-right: 10px;
    color: #4A90E2;
    font-size: 1.5em;
}

.contact-info-section p a {
    text-decoration: none;
    color: inherit;
    word-wrap: break-word;
}

.contact-info-section p a:hover {
    color: #003366;
}

.contact-info-section p:hover {
    transform: scale(1.02);
    transition: transform 0.2s ease-in-out;
}

.contact-info-section h2 {
    font-size: 1.8em;
    margin-bottom: 1.5em;
}

.form-section {
    flex: 1;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding-bottom: 2em;
}

.form-section h2 {
    margin-right: 1em;
}

.info-section {
    flex: 1;
    flex-direction: column;
    justify-content: center;
    padding-right: 2em;
    position: relative;
}

.separator {
    position: absolute;
    margin-top: 50px;
    height: 60%;
    width: 1px;
    background-color: #ccc;
    left: 66%;
    transform: translateX(-41%);
}

.gallery-container h1 {
    font-size: 2em;
    margin-bottom: 1em;
}

.gallery-container h2 {
    font-size: 1.75em;
    margin-bottom: 1em;
}

.form-group {
    display: flex;
    gap: 1em;
    margin-bottom: 1em;
    margin-right: 2em;
}

input[type="text"],
input[type="email"],
textarea {
    width: 100%;
    padding: 0.75em;
    border: 1px solid #ccc;
    border-radius: 8px;
    font-size: 1em;
}

textarea {
    resize: none;
    height: 150px;
}

.submit-button {
    padding: 1em;
    background-color: #4A90E2;
    color: white;
    border: none;
    border-radius: 8px;
    cursor: pointer;
    font-size: 1.1em;
    transition: background-color 0.3s, transform 0.3s;
}

button:hover {
    background-color: #003366;
    transform: scale(1.05);
}

.info-section p {
    margin-bottom: 1em;
}

.team-section {
    padding: 2em;
    padding-top: 0em;
    text-align: center;
}

.team-cards {
    display: flex;
    justify-content: center;
    flex-wrap: nowrap;
    gap: 2em;
    margin-top: 1em;
}

.team-cards>* {
    flex: 0 0 200px;
    max-width: 200px;
}

.gallery-carousel {
    text-align: center;
}

.gallery-carousel-slider {
    margin: 0 auto;
    width: 80%;
    max-width: 800px;
}

.gallery-image img {
    max-width: 98%;
    height: auto;
    max-height: 500px;
    object-fit: cover;
    border-radius: 8px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    margin: auto;
}

@media (max-width: 768px) {
    .contact-form-wrapper {
        flex-direction: column;
        margin: 1em;
        padding: 1em;
    }

    .contact-info-section {
        max-width: 100%;
        margin-bottom: 1.5em;
        border-right: none;
        box-shadow: none;
        padding: 1em;
    }

    .form-group {
        flex-direction: column;
        margin-right: 0;
    }

    input[type="text"],
    input[type="email"],
    textarea {
        width: 100%;
    }

    .submit-button {
        width: 100%;
        margin-top: 1em;
    }

    .separator {
        display: none;
    }

    .gallery-carousel {
        padding-top: 2em;
        width: 100%;
    }
}

@media (max-width: 480px) {
    section#gallery {
        padding: 0.5em;
    }

    .contact-form-wrapper {
        margin: 0.5em;
        padding: 0.5em;
    }

    .contact-info-section {
        padding: 0.5em;
    }

    .team-cards {
        flex-wrap: wrap;
    }
    .form-section {
        padding: 0.5em;
    }

    .form-group {
        margin-bottom: 0.5em;
    }

    .submit-button {
        padding: 0.75em;
        font-size: 1em;
    }
}
