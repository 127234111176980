/* src/components/Equipment.css */

section#qualities {
  display: flex;
}

.qualities-content {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: stretch;
  flex: 1;
}

.qualities-text-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  flex: 1;
  box-shadow: 0 15px 30px -15px rgba(0, 0, 0, .45);
  border-radius: 8px;
  padding: 2em;
  max-width: 50%;
}

.qualities-text {
  margin-right: 2em;
  padding-left: 4em;
}

.qualities-image {
  flex: 1;
  text-align: center;
  max-height: 500px;
}

.qualities-image img {
  max-width: 100%;
  height: 100%;
  border-radius: 8px;
}

@media (max-width: 768px) {
  section#qualities {
    flex-direction: column;
    padding: 2em; 
  }
  
  .qualities-content {
    flex-direction: column;
    justify-content: center;
  }
  
  .qualities-text-container {
    padding: 1em;
    max-width: 100%;
    margin-bottom: 1em;
  }

  .qualities-image img {
    height: auto;
  }

  .qualities-text {
    margin-left: 0;
    text-align: center;
    padding: 0em;
    margin-right: 0;
  }
}