/* src/components/AboutUs.css */

section#about-us {
  position: relative;
  padding: 3em;
  margin-top: 0em;
}

.about-us-content {
  display: flex;
  justify-content: space-between;
  align-items: center;
  position: center;
  z-index: 1;
}

.about-us-text {
  flex: 1;
  z-index: 1;
  padding: 2em;
  text-align: center;
}

.about-us-text h2 {
  color: white;
}

.about-us-text p {
  color: white;
}

.about-us-image {
  flex: 1;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  overflow: hidden;
  z-index: 0;
}

.about-us-image img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  filter: blur(15px);
}

.blur-background {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(255, 255, 255, 0.7);
  z-index: 0;
}

@media (max-width: 768px) {
  .about-us-text {
    margin-top: 0em;
    padding-top: 0em;
  }
}