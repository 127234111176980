#cookies-policy {
    padding: 40px;
}

#cookies-policy h1,
#cookies-policy h2,
#cookies-policy h3 {
    margin-bottom: 20px;
    font-weight: 600;
}

#cookies-policy h1 {
    font-size: 2.5em;
    margin-bottom: 30px;
}

#cookies-policy h2 {
    font-size: 2em;
    margin-top: 40px;
    margin-bottom: 15px;
}

#cookies-policy h3 {
    font-size: 1.5em;
    margin-bottom: 10px;
}

#cookies-policy p,
#cookies-policy ul {
    font-size: 1rem;
    line-height: 1.75;
    margin-bottom: 15px;
}

#cookies-policy ul {
    list-style: disc;
    padding-left: 20px;
}

#cookies-policy ul li {
    margin-bottom: 10px;
}

@media (max-width: 768px) {
    #cookies-policy {
        padding: 20px;
    }

    #cookies-policy h1 {
        font-size: 2em;
    }

    #cookies-policy h2 {
        font-size: 1.75em;
    }

    #cookies-policy h3 {
        font-size: 1.25em;
    }
}