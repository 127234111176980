/* src/App.css */

.blur-background {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100vh;
  z-index: -1;
}

.main-content {
  position: relative;
  z-index: 1;
}

body {
  font-family: 'Roboto', sans-serif;
  margin: 0;
  padding: 0;
  background-color: #FFFFFF;
  color: #003366;
}

section {
  padding: 4em;
  margin-top: 2em;
}

h1, h2 {
  color: #4A90E2;
  font-size: 3em;
  text-shadow: 2px 2px 5px rgba(0, 0, 0, 0.1);
  background: linear-gradient(135deg, #0077ff, #333030);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}

h3 {
  color: #4A90E2;
  text-shadow: 2px 2px 5px rgba(0, 0, 0, 0.1);
  background: linear-gradient(135deg, #0077ff, #333030);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}

p {
  color: #003366;
  font-size: 1.25em;
  text-shadow: 2px 2px 5px rgba(0, 0, 0, 0.05);
}
