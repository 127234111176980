/* src/components/AnimatedComponent.css */

.animated-component {
    opacity: 0;
    transform: translateY(20px);
    transition: opacity 0.8s ease, transform 0.8s ease;
  }
  
  .animated-component.animate {
    opacity: 1;
    transform: translateY(0);
  }
  