section#strengths {
  padding: 4em 2em;
  background-color: #f5f5f5;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-top: 0em;
}

.strengths-title {
  font-size: 2.5em;
  margin-bottom: 1em;
  text-align: center;
  color: #333;
}

.strengths-container {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  gap: 2em;
}
