/* src/components/Services.css */

section#services {
  display: flex;
}

.services-content {
  display: flex;
  flex-direction: row-reverse;
  justify-content: space-between;
  align-items: stretch;
  flex: 1;
}

.services-text-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  flex: 1;
  padding: 2em;
  border-radius: 8px;
  max-width: 50%;
  box-shadow: 0 15px 30px -15px rgba(0, 0, 0, .45);
}

.services-text {
  margin-left: 2em;
}

.services-image {
  flex: 1;
  text-align: center;
}

.services-image img {
  max-width: 100%;
  height: 100%;
  border-radius: 8px;
}

.stats-cards-container {
  display: flex;
  flex-wrap: inherit;
  gap: 1em;
  margin-top: 1em;
}

.stats-cards-container .stats-card {
  flex: 1 1 25%;
  max-width: 100%;
  min-width: 150px;
}

@media (max-width: 768px) {
  section#services {
    flex-direction: column;
    padding: 2em;
  }

  .services-content {
    flex-direction: column;
    justify-content: center;
  }

  .services-text-container {
    padding: 1em;
    max-width: 100%;
    margin-bottom: 1em;
  }

  .services-text {
    margin-left: 0;
    text-align: center;
  }

  .services-image img {
    height: auto;
  }

  .stats-cards-container {
    flex-wrap: wrap;
  }

  .stats-cards-container .stats-card {
    flex: 1 1 100%;
  }
}